import { capitalize } from "@mui/material";
import { Stack, Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import {
  isValid,
  sub,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  isBefore,
  isAfter,
} from "date-fns";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import MultiSelect, { Value } from "./MultiSelect";
import { useCallback, useEffect, useState } from "react";

enum PredefinedDateRange {
  CUSTOM = "custom",
  LAST_MONTH = "lastMonth",
  PAST_WEEK = "pastWeek",
  CURRENT_MONTH = "currentMonth",
  CURRENT_YEAR = "currentYear",
}

const rangeFilterOptions = (t: TFunction) => [
  {
    value: PredefinedDateRange.CUSTOM,
    label: t("usermanagement.filters.custom"),
  },
  {
    value: PredefinedDateRange.LAST_MONTH,
    label: t("usermanagement.filters.lastMonth"),
  },
  {
    value: PredefinedDateRange.PAST_WEEK,
    label: t("usermanagement.filters.pastWeek"),
  },
  {
    value: PredefinedDateRange.CURRENT_MONTH,
    label: t("usermanagement.filters.currentMonth"),
  },
  {
    value: PredefinedDateRange.CURRENT_YEAR,
    label: t("usermanagement.filters.currentYear"),
  },
];

interface DateRangePickerProps {
  from?: Date | null;
  to?: Date | null;
  setFrom: (newDate?: Date | null) => void;
  setTo: (newDate?: Date | null) => void;
}

function DateRangePicker({ from, to, setFrom, setTo }: DateRangePickerProps) {
  const { t } = useTranslation();

  const options = rangeFilterOptions(t);

  const [rangeFilterSelection, setRangeFilterSelection] =
    useState<Value<(typeof options)[number]>>();

  const handleSelectionChange = useCallback(
    (value: PredefinedDateRange) => {
      setRangeFilterSelection(value);

      if (value === PredefinedDateRange.CUSTOM) {
        setFrom(null);
        setTo(null);
      }

      switch (value) {
        case PredefinedDateRange.PAST_WEEK:
          const lastWeek = sub(new Date(), { weeks: 1 });
          setFrom(startOfWeek(lastWeek));
          setTo(endOfWeek(lastWeek));
          break;
        case PredefinedDateRange.LAST_MONTH:
          const lastMonth = sub(new Date(), { months: 1 });
          setFrom(startOfMonth(lastMonth));
          setTo(endOfMonth(lastMonth));
          break;
        case PredefinedDateRange.CURRENT_MONTH:
          const thisMonth = new Date();
          setFrom(startOfMonth(thisMonth));
          setTo(endOfMonth(thisMonth));
          break;
        case PredefinedDateRange.CURRENT_YEAR:
          const thisYear = new Date();
          setFrom(startOfYear(thisYear));
          setTo(endOfYear(thisYear));
          break;
      }
    },
    [setFrom, setTo]
  );

  useEffect(() => {}, [rangeFilterSelection, setFrom, setTo]);

  return (
    <Stack direction="column">
      <MultiSelect
        name="dateRangeFilter"
        options={options}
        value={rangeFilterSelection}
        onChange={(option) =>
          !Array.isArray(option) && handleSelectionChange(option)
        }
        sx={{ mt: 0 }}
        renderValue={(option) => (
          <span>
            {!option || Array.isArray(option)
              ? capitalize(t("nothingSelected"))
              : t(option.label)}
          </span>
        )}
      />
      {rangeFilterSelection === PredefinedDateRange.CUSTOM && (
        <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
          <DatePicker
            value={from}
            maxDate={to}
            onChange={(newValue) => {
              if (!newValue) {
                setFrom(null);
              } else if (isValid(newValue)) {
                if (to && isAfter(newValue, to)) {
                  setFrom(null);
                } else {
                  setFrom(newValue ?? null);
                }
              }
            }}
            format="dd.MM.yyyy"
            slotProps={{
              textField: {
                sx: { ".MuiOutlinedInput-root": { marginTop: 0 } },
              },
            }}
          />
          <Box sx={{ mx: 2 }}> {t("to")} </Box>
          <DatePicker
            value={to}
            minDate={from}
            onChange={(newValue) => {
              if (!newValue) {
                setTo(null);
              } else if (isValid(newValue)) {
                if (from && isBefore(newValue, from)) {
                  setTo(null);
                } else {
                  setTo(newValue ?? null);
                }
              }
            }}
            format="dd.MM.yyyy"
            slotProps={{
              textField: {
                sx: { ".MuiOutlinedInput-root": { marginTop: 0 } },
              },
            }}
          />
        </Stack>
      )}
    </Stack>
  );
}

export default DateRangePicker;
