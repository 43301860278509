import {
  Grid,
  Box,
  capitalize,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import muiTheme from "../../theme";
import { Head } from "../../components/DataGrid";
import { endOfDay } from "date-fns";
import Amount from "../../components/Amount";
import { useEffect, useMemo, useState } from "react";
import {
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarExportContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import {
  useGetCoffeeMenusQuery,
  useGetLocationsQuery,
  useGetUserGroupsQuery,
  useGetUsersConsumptionQuery,
} from "../../state/services/api";
import { formatMoney } from "../../utils/money";
import { useSelector } from "../../state/hooks";
import { selectCompany } from "../../state/auth";
import { uniq } from "lodash";
import {
  ConsumptionFilter,
  UserConsumptions,
  UserFilter,
} from "../../state/users/adapter";
import DateRangeFilter from "./filters/dateRange";
import MultiselectFilter from "./filters/multiSelect";
import { mapToOptions } from "../../utils/object";
import { Consumption, productName } from "../../state/products";
import { ellipsisOverflow } from "../../utils/string";
import { getAll } from "../../utils/api";
import AggregateTable from "../../components/AggregateTable";
import { renderCellExpand } from "../../components/GridCellExpand";
import ExportMenuItem from "../../components/ExportMenuItem";
import { useUsersConsumptionExport } from "../../hooks/usersConsumptionExport";
import { ExportType } from "../../hooks/export";
import SwitchFilter from "./filters/switch";

const NO_GROUP_FILTER_KEY = "__no_group";

interface UserConsumptionProps {
  export?: boolean;
  page?: number;
  pageSize?: number;
  filter?: UserFilter & ConsumptionFilter;
}

function showConsumption(
  menuIds: string[] | undefined,
  consumption: Consumption
) {
  const showAll = !menuIds?.length;
  return (
    showAll ||
    (consumption.product.coffeeMenuId &&
      menuIds?.includes(consumption.product.coffeeMenuId))
  );
}

function consumptionsForProduct(
  consumptions: Consumption[],
  productId: string
) {
  return consumptions.filter(({ product }) => product.id === productId);
}

function useFilter(filterProp: UserConsumptionProps["filter"]) {
  const [filter, setFilter] = useState({
    from: filterProp?.from !== undefined ? new Date(filterProp.from) : null,
    to: filterProp?.to !== undefined ? new Date(filterProp.to) : null,
  } as UserFilter & ConsumptionFilter);

  const { data: allMenus } = useGetCoffeeMenusQuery();
  const { data: userGroups } = useGetUserGroupsQuery();
  const { data: locations } = useGetLocationsQuery();

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      menuIds: getAll(allMenus).map(({ id }) => id),
      userGroupIds: getAll(userGroups).map(({ id }) => id),
      locationIds: getAll(locations).map(({ id }) => id),
      noUserGroup: true,
      noLocation: true,
    }));
  }, [allMenus, locations, userGroups]);

  return [filter, setFilter] as [typeof filter, typeof setFilter];
}

function Toolbar({
  filter,
  ...other
}: { filter: UserFilter & ConsumptionFilter } & GridToolbarContainerProps) {
  const csvExport = useUsersConsumptionExport(ExportType.CSV, filter);
  const xlsxExport = useUsersConsumptionExport(ExportType.XLSX, filter);
  const { t } = useTranslation();

  return (
    <GridToolbarContainer {...other}>
      <GridToolbarExportContainer
        sx={{ width: "auto" }}
        disabled={!filter.ids?.length}
      >
        <ExportMenuItem
          onClick={async () => {
            await csvExport();
          }}
        >
          {t("usermanagement.export.exportCSV")}
        </ExportMenuItem>
        <ExportMenuItem
          onClick={async () => {
            await xlsxExport();
          }}
        >
          {t("usermanagement.export.exportXLSX")}
        </ExportMenuItem>
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}

function UserConsumption({
  filter: filterProp,
  export: exportEnabled = false,
  pageSize = 10,
}: UserConsumptionProps) {
  const { t } = useTranslation();
  const { t: tm } = useTranslation("machine");

  const company = useSelector(selectCompany);

  const { data: allMenus } = useGetCoffeeMenusQuery();
  const { data: userGroups } = useGetUserGroupsQuery();
  const { data: locations } = useGetLocationsQuery();

  const [filter, setFilter] = useFilter(filterProp);

  const { data: userConsumptions = [] } = useGetUsersConsumptionQuery({
    ...Object.fromEntries(
      Object.entries(filter).filter(([key]) => key !== "ids")
    ),
    ...(filter.from && { from: filter.from.toString() }),
    ...(filter.to && { to: endOfDay(filter.to).toString() }),
  });

  const consumptions = useMemo(
    () => userConsumptions?.flatMap(({ consumptions }) => consumptions) ?? [],
    [userConsumptions]
  );

  const productIds = useMemo(
    () => uniq(consumptions?.map(({ product }) => product.id)) ?? [],
    [consumptions]
  );

  const smScreen = useMediaQuery<typeof muiTheme>((theme) =>
    theme.breakpoints.up("sm")
  );

  const aggregateRows = useMemo(() => {
    const userId = "## userid ##";
    const fakeUser = {
      id: userId,
      email: "",
      firstName: capitalize(t("usermanagement.consumption.all")),
      lastName: "",
    };
    const userConsumption =
      productIds.map((productId) => {
        const productConsumptions = consumptionsForProduct(
          consumptions,
          productId
        );
        return {
          product: productConsumptions[0].product ?? null,
          currency: company?.currency ?? "CHF",
          amount: productConsumptions.reduce(
            (total, consumption) => total + (consumption?.amount ?? 0),
            0
          ),
          price: productConsumptions.reduce(
            (total, consumption) => total + (consumption?.price ?? 0),
            0
          ),
        } as Consumption;
      }) ?? [];

    return [
      {
        id: "## all ##",
        user: fakeUser,
        consumptions: userConsumption,
      } as UserConsumptions,
    ];
  }, [company?.currency, consumptions, productIds, t]);

  const baseColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "user.firstName",
        cellClassName: "prominent",
        headerName: t("usermanagement.tableHeader.name"),
        minWidth: exportEnabled ? (smScreen ? 130 : 0) : 100,
        headerAlign: "left",
        align: "left",
        renderCell: renderCellExpand,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.user.firstName ?? params.row.user.email,
      },
      {
        field: "user.lastName",
        headerName: t("usermanagement.tableHeader.surname"),
        minWidth: !exportEnabled ? 150 : 0,
        headerAlign: "center",
        align: "center",
        renderCell: renderCellExpand,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.user.lastName,
      },
    ],
    [exportEnabled, smScreen, t]
  );

  const productColumns: GridColDef[] = useMemo(() => {
    return productIds.flatMap((productId) => {
      const cs = consumptionsForProduct(consumptions, productId);
      const product = cs[0]?.product;
      const showMenu = !filter.menuIds?.length || filter.menuIds.length > 1;

      if (!showConsumption(filter.menuIds, cs[0])) {
        return [];
      }

      const menuName = showMenu ? ` (${product.coffeeMenu?.name})` : "";

      return [
        {
          field: `${productId}-amount`,
          headerName: `${ellipsisOverflow(productName(tm, product), 25)} ${t(
            "usermanagement.consumption.numConsumed"
          )}${menuName}`,
          headerClassName: "wrap-header",
          align: "center",
          headerAlign: "center",
          width: 160,
          valueGetter: (params: GridValueGetterParams) =>
            consumptionsForProduct(params.row.consumptions, productId)?.[0]
              ?.amount ?? 0,
        },
        {
          field: `${productId}-price`,
          headerName: `${ellipsisOverflow(productName(tm, product), 25)} - ${t(
            "usermanagement.consumption.totalCost"
          )}${menuName}`,
          headerClassName: "wrap-header",
          align: "right",
          headerAlign: "center",
          width: 140,
          valueGetter: (params: GridValueGetterParams) => {
            const consumption = consumptionsForProduct(
              params.row.consumptions,
              productId
            )?.[0];
            if (!consumption) return formatMoney(0, company?.currency ?? "CHF");
            return formatMoney(consumption.price, consumption.currency);
          },
        },
      ];
    });
  }, [company?.currency, consumptions, filter.menuIds, productIds, t, tm]);

  const aggregateColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "totalConsumption",
        headerName: t("usermanagement.consumption.totalConsumption"),
        headerClassName: "wrap-header",
        cellClassName: "rowTotal",
        align: "right",
        headerAlign: "right",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.consumptions
            .filter((consumption: Consumption) =>
              showConsumption(filter.menuIds, consumption)
            )
            .reduce(
              (total: number, consumption: Consumption) =>
                total + consumption.amount,
              0
            ),
      },
      {
        field: "totalPrice",
        headerName: t("usermanagement.consumption.totalCost"),
        headerClassName: "wrap-header",
        cellClassName: "rowTotal",
        align: "right",
        headerAlign: "right",
        flex: 1,
        renderCell: (params: GridRenderCellParams<any, number>) =>
          params.value
            ? formatMoney(
                params.value,
                params.row.currency ?? company?.currency ?? "CHF"
              )
            : formatMoney(0, company?.currency ?? "CHF"),
        valueGetter: (params: GridValueGetterParams) =>
          params.row.consumptions
            .filter((consumption: Consumption) =>
              showConsumption(filter.menuIds, consumption)
            )
            .reduce(
              (total: number, consumption: Consumption) =>
                total + consumption.price,
              0
            ),
      },
    ],
    [company?.currency, filter.menuIds, t]
  );

  return (
    <Grid item xs={12}>
      <Paper>
        <Stack>
          <Stack py={10} pl={2} borderBottom="1px solid #E0E2E7">
            <Amount
              amount={userConsumptions?.length}
              text={t("usermanagement.numberOfUsers")}
            />
          </Stack>
          <Head>
            <Typography variant="h2" mb={5}>
              {capitalize(t("filter"))}
            </Typography>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
              flexWrap="wrap"
              rowGap={4}
              columnGap={4}
            >
              <Stack
                flex={1}
                flexDirection={{ lg: "row" }}
                rowGap={4}
                columnGap={4}
                justifyContent="flex-end"
              >
                {/*
                <SelectFilter
                  name="status"
                  label={t("usermanagement.filter.status")}
                  options={Object.values(Status).map((value) => ({
                    label: capitalize(t(value)),
                    value: value,
                  }))}
                  value={statusValue}
                  setValue={handleStatusChange}
                /> */}
                <Box
                  display="grid"
                  flex={1}
                  gap={3}
                  gridTemplateColumns="repeat(auto-fill, minmax(14rem, 1fr))"
                  sx={{ placeContent: "start" }}
                >
                  <MultiselectFilter
                    label={t("usermanagement.filter.location")}
                    options={mapToOptions(
                      locations,
                      (location) => location.name
                    )}
                    setValues={(ids) =>
                      ids && setFilter((f) => ({ ...f, locationIds: ids }))
                    }
                    values={filter.locationIds ?? []}
                  />

                  <MultiselectFilter
                    label={t("usermanagement.filter.userGroup")}
                    options={[
                      {
                        label: t("usermanagement.filter.noUserGroup"),
                        value: NO_GROUP_FILTER_KEY,
                      },
                    ].concat(
                      mapToOptions(userGroups, (userGroup) => userGroup.name)
                    )}
                    setValues={(selected) => {
                      const noUserGroupSelected =
                        selected?.includes(NO_GROUP_FILTER_KEY);
                      const ids = selected?.filter(
                        (value) => value !== NO_GROUP_FILTER_KEY
                      );
                      setFilter((f) => ({
                        ...f,
                        userGroupIds: ids,
                        noUserGroup: noUserGroupSelected,
                      }));
                    }}
                    values={(filter.noUserGroup
                      ? [NO_GROUP_FILTER_KEY]
                      : []
                    ).concat(filter.userGroupIds ?? [])}
                  />
                  <MultiselectFilter
                    label={t("usermanagement.filter.coffeeMenu")}
                    options={mapToOptions(allMenus, (menu) => menu.name)}
                    setValues={(ids) =>
                      ids && setFilter((f) => ({ ...f, menuIds: ids }))
                    }
                    values={filter.menuIds ?? []}
                  />

                  <DateRangeFilter
                    from={filter.from ?? null}
                    to={filter.to ?? null}
                    setFrom={(from) =>
                      setFilter((f) => ({ ...f, from: from ?? undefined }))
                    }
                    setTo={(to) =>
                      setFilter((f) => ({ ...f, to: to ?? undefined }))
                    }
                  />

                  <SwitchFilter
                    label={t("usermanagement.filter.noLocation")}
                    checked={filter.noLocation}
                    onChange={(e) => {
                      setFilter((f) => ({
                        ...f,
                        noLocation: !f.noLocation,
                      }));
                    }}
                  />
                </Box>
              </Stack>
            </Stack>
          </Head>
          <Stack flexDirection="row" alignItems="flex-start">
            <AggregateTable
              pageSize={pageSize}
              export={!!exportEnabled}
              filter={filter}
              rows={userConsumptions}
              aggregateRows={aggregateRows}
              columns={baseColumns.concat(productColumns)}
              aggregateColumns={aggregateColumns}
              Toolbar={Toolbar}
              selectionModel={filter?.ids ?? []}
              onSelectionModelChange={(selectionModel) =>
                setFilter?.((f) => ({ ...f, ids: selectionModel as string[] }))
              }
            />
          </Stack>
        </Stack>
      </Paper>
    </Grid>
  );
}

export default UserConsumption;
